.close-toggle-button{
    margin: 2rem 2rem 0 0;
    width: 50px;
    height: 50px;
    background: transparent;
    border: none;
    /* border: 1px solid white; */
    cursor:pointer;
    padding: 0;
    box-sizing: border-box;
    position: fixed;
    top:0;
    right:0;
}
.close-toggle-button:focus{
    outline:none;
}

.close-toggle-button-line-1{
    width: 60px;
    height: 47px;
    /* border: 1px solid white; */
    border-bottom: 4px solid white;
    border-radius: 3px;
    transform: 
        translateY(-20px)
        translateX(60px)
        rotate(45deg); 
    position: absolute;
    top: 10px;
    left: -50px;
}
.close-toggle-button-line-2{
    width: 60px;
    height: 47px;
    /* border: 1px solid white; */
    border-bottom: 4px solid white;
    border-radius: 3px;
    transform:
        translateY(20px)
        translateX(40px)
        rotate(-45deg);
    position: absolute;
    top: -30px;
    left: -65px;
}
