.toggle-button{
    display:flex;
    flex-direction: column;
    justify-content:space-around;
    height: 50px;
    width: 50px;
    background: transparent;
    border:none;
    /* border: 1px solid black; */
    cursor:pointer;
    padding: 0;
    box-sizing: border-box;
    margin-right: 2rem;
}

.toggle-button:focus{
    outline:none;
}

.toggle-button-line{
    width:50px;
    height:4px;
    border-radius: 2px;
    background:#ff0000;
}

@media(min-width: 769px){
    .toggle-button{
        display:none;
    }
}
