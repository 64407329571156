a{
    color: white; 
}
.toolbar{
    width:100%; 
}

.toolbar-logo a{
    color: black;
    margin-left: 3rem;
}

.toolbar-navigation{
    width:100%;
    height: 9rem;
    display:flex;
    justify-content: space-between;
    align-items: center;
}

.spacer{
    flex:1;
}

.toolbar-navigation-items{
    /* border: 1px solid white; */
    width: 80%;
    height: 5.2rem;
    display:flex;
    justify-content: space-evenly;
    margin: auto;
    align-items: center;
    color: white;    
    background-color:rgb(18,19,18);opacity:0.9;
}

.toolbar-navigation-items a{
    text-decoration:none;
}

.toolbar-navigation-items a:hover,
.toolbar-navigation-items a:active,
.dropbtn:hover {
    color:red;
}

.dropDownNavLink{
    text-align:center;
    margin: auto;
    width: 150px;
    text-decoration: none;
    color: white;
    display: block;
    padding: 1rem;
}
.dropbtn {
    text-decoration: none;
    color: white;
    display: block;
    cursor: pointer;
    text-align:center;
}
.dropdown {
/* margin: auto;   */
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: black;
    width:15rem;
    z-index: 1;
}


.dropdown-content :hover {
    margin: auto;
    background-color: #ddd;
    width:85%;
}

.dropdown:hover .dropdown-content {display: block;}

.donateButton{
    border: 1px solid red;
    background-color: red;
    border-radius: 2rem;
    cursor:pointer;
    color:#ffffff;
    padding: 0.5rem 1rem;
    text-decoration:none;
    text-align:center;
    width: 10rem;
}

.donateButton:hover {
    background-color:white;
    border: 1px solid white;
    color:#ff0000;
    text-decoration:none;
}

@media(max-width: 769px){
    
    .toolbar-logo a{
        margin-left: 1rem;
    }

    .toolbar-navigation{
        height: 8rem;
        width:99.5%;
        background-image: linear-gradient(#5b5959, transparent)
    }

    .toolbar-navigation-items{
        display: none;
    }

    .dropbtn {
        padding: 1rem;
        margin: 0 auto;
        flex-direction: column;
    }
}
